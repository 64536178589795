module.exports.PageLinks = {
    about: "about-michael-jones",
    general_enquiry: "contact/general-enquiry",
    news_insight: `news-and-insights`,
    branch_finder: "branch-finder",
    area_cover: "areas-we-cover",
    branch_contact: "contact-us",
    team_contact: "contact-us",
    teams: "contact/meet-the-team", 
    request_valuation: "request-a-valuation",
    privacy_policy: "privacy-policy",
    book_a_viewing: "book-a-viewing",
    book_a_valuation: "request-a-valuation/home-visit-valuation",
    send_to_friend: "send-to-friend",
    property_to_rent: "property/to-rent",
    property_for_sale: "property/for-sale",
    reviews: "about-michael-jones/customer-reviews",
    property_management: "landlords/property-management",
    financial_services: `financial-services`,
    new_homes: "new-homes/for-sale",
    new_developments: "new-developments/for-sale",
    online_payment: "online-payment",
    payment_success: "payment-success",
    payment_failed: "payment-failed",
    payment_verify: "online-payment-verification",
    no_deposit: "rent-property/no-deposit-option",
    landlords: "landlords",
    guides_resources: "guides-and-resources",
    contact_investment_centre: "contact-investment-centre",
    investment_centre: "investment-centre",
}